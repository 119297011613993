<template>
  <v-carousel hide-delimiters show-arrows-on-hover>
    <v-carousel-item
      :src="slider.src"
      reverse-transition="fade-transition"
      transition="fade-transition"
      :link="slider.link && !slider.title"
      :href="slider.link && !slider.title ? slider.link : null"
      target="_blank"
    >
      <v-row
        v-if="slider.title || slider.text || slider.link"
        class="d-flex flex-column align-center justify-center fill-height px-16"
      >
        <div class="display-2">
          {{ slider.title }}
        </div>
        <div v-if="slider.title" class="divider"></div>
        <div class="text-center subtitle-1">
          <p>
            {{ slider.text }}
          </p>
        </div>
        <v-btn
          v-if="slider.link"
          elevation="0"
          large
          color="white"
          outlined
          :href="slider.link"
          target="_blank"
          >Enlace</v-btn
        >
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "PreviewHeader",
  data: () => ({}),
  props: { slider: Object },
};
</script>

<style></style>
